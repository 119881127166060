import React from "react";
import Slider from "@material-ui/core/Slider";
import Logo from "../assets/logo-e-nundation_transparent.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faWater,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as ModeCrise} from "../assets/icons/ico_modecrise.svg";
import {ReactComponent as Repeat} from "../assets/icons/ico_repeat.svg";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
import './Header.css';


const ScaleEntry = ({scaleMarksList}) => (
  <>
    {scaleMarksList.map((scaleMark) => (
      <div
        className="header_slider_input_scale"
        style={scaleMark.style}
        key={scaleMark.value}
      >
        {scaleMark.label}
      </div>
    ))}
  </>
);

const scaleMarksList = [];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.scenarioDepart,
      // value: this.props.currentScenario,
    };
    this.updateSliderValue = this.updateSliderValue.bind(this);
    this.generateScaleMarksList();
  }

  generateScaleMarksList() {
    let uniteMesure = this.props.typeMesureDebit ? " m³/s" : " m";
    const scale = 20;
    const max = this.props.nbScenario - 1;
    const min = 0;
    const padding = 0;

    for (let index = min; index < max; index += scale) {
      const position = Math.round((index / (max - min)) * 100);

      if (position < 100) {
        scaleMarksList.push({
          value: index,
          style: {left: `calc(${position}% + ${padding}px)`},
          // label: `${this.props.labels[index]} ${this.props.uniteMesure}`,
          label: `${this.props.labels[index]} ${uniteMesure}`,
          // label: this.props.labels[index] + uniteMesure,
        });
      }
    }
  }

  updateSliderValue(value) {
    this.setState({value: parseInt(value.target.value, 10)});
    const waterLevelEvent = new CustomEvent("waterLevel", {
      detail: parseInt(value.target.value, 10),
    });
    document.dispatchEvent(waterLevelEvent);
  }

  EmitSliderValue(value) {
    this.setState({value: value});
    const waterLevelEvent = new CustomEvent("waterLevel", {detail: value});
    document.dispatchEvent(waterLevelEvent);
  }

  // componentWillMount() {
  componentDidMount() {
    // Set previsions
    fetch('https://previsions-geosapiens.s3.ca-central-1.amazonaws.com/previsions.json')
      .then(res => res.json())
      .then((previsions) => {
        // console.log('Output: ', previsions);
        // console.log(Object.entries(previsions));
        for (const [river, scenarios] of Object.entries(previsions)) {
          if (river === this.props.setApp) {
            this.props.onSetBtnHier(scenarios["hier"]);
            this.props.onSetBtnReel(scenarios["temps_reel"]);
            this.props.onSetBtn1J(scenarios["un_jour"]);
            this.props.onSetBtn3J(scenarios["trois_jours"]);
            this.props.onSetBtn7J(scenarios["sept_jours"]);
          }
        }
      }).catch(err => console.error(err));
  }

  render() {
    // Change type of mesure
    let typeMesure = this.props.typeMesureDebit ? "Débit" : "Niveau d'eau";
    let uniteMesure = this.props.typeMesureDebit ? " m³/s" : " m";

    return (
      <header className="header">
        <img className="header_logo" src={Logo} alt="logo"/>


        {/*Menu pour changer le debit en hauteur*/}
        {/*<select className="esri-widget"*/}
        {/*        id="attSelect"*/}
        {/*        defaultValue={"'Isolé (route inondée)' OR ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé' OR ETAT = 'Eau atteint le bâtiment'"}>*/}
        {/*  <option*/}
        {/*    value="'Isolé (route inondée)' OR ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'*/}
        {/*    OR ETAT = 'Eau atteint le bâtiment'">À risque*/}
        {/*  </option>*/}
        {/*  <option value="'Isolé (route inondée)'">Isolé</option>*/}
        {/*  <option*/}
        {/*    value="'Sous-sol inondé' OR ETAT = 'Eau atteint le bâtiment' OR ETAT = 'Sous-sol et premier plancher inondé'">*/}
        {/*    Sous-sol inondé*/}
        {/*  </option>*/}
        {/*  <option value="'Sous-sol et premier plancher inondé'">1er plancher inondé</option>*/}
        {/*  <option*/}
        {/*    value="'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'*/}
        {/*    OR ETAT = 'Eau atteint le bâtiment'">Inondé*/}
        {/*  </option>*/}
        {/*</select>*/}


        <div className="slider_container">
          <div className="slider_header_container">
            <div className="slider_header_wrapper">
              <div className="header_slider">
                                <span className="header_slider_text">
                                    <FontAwesomeIcon icon={faWater}/>{" "}
                                  <span className="typeMesureContainer">
                                        {typeMesure}
                                    </span>
                                 </span>
                <span className="header_slider_text_below">
                                    <Repeat/>{" "}
                  <span className="typeMesureContainer">
                                        {this.props.criseOptionsLabel.rec}
                    <br/>
                    {this.props.criseOptionsLabel.annees}
                                    </span>
                                </span>
              </div>
              <div className="slider_header_container_wrap">
                <ScaleEntry scaleMarksList={scaleMarksList}/>

                <Slider
                  id="slider"
                  className="header_slider_input"
                  min={0}
                  max={this.props.nbScenario - 1}
                  step={1}
                  defaultValue={this.props.scenarioDepart}
                  value={this.state.value}
                  valueLabelFormat={`${this.props.labels[this.state.value]} ${uniteMesure}`}
                  valueLabelDisplay="on"
                  marks={this.props.yearMarkers}
                  onChange={(_, value) => this.EmitSliderValue(value)}
                />
              </div>
            </div>
            <div className="gestion_crise">
              <button
                id="btnCrise"
                className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}
                onClick={this.props.onCriseToggle}
              >
                <ModeCrise className={this.props.toggleCrise ? "btnModeCriseTrue" : "btnModeCriseFalse"}/>
                <span>
                                    {this.props.toggleCrise ? "Fermer le mode gestion de crise" : "Ouvrir le mode gestion de crise"}
                                </span>
                <FontAwesomeIcon id="arrowUp" icon={faArrowUp}
                                 style={{display: this.props.toggleCrise ? 'block' : 'none'}}/>
                <FontAwesomeIcon id="arrowDown" icon={faArrowDown}
                                 style={{display: this.props.toggleCrise ? 'none' : 'block'}}/>
              </button>
              <button
                id="btnManualMode"
                // className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}
                onClick={this.props.onManualModeToggle}
              >
                                <span>
                                    Mode manuel
                                </span>
              </button>
              <div id="div-manual" style={{display: this.props.toggleManualMode ? 'block' : 'none'}}/>
            </div>
            <div className="header_actions" style={{display: this.props.toggleCrise ? 'block' : 'none'}}>
              <button
                id="btn_2019"
                onClick={this.updateSliderValue}
                value={this.props.btn2019}
              >
                {this.props.criseOptionsLabel.btn2019}
              </button>
              <button
                id="btn_hier"
                onClick={this.updateSliderValue}
                value={this.props.setBtnHier}
                disabled={this.props.setBtnHier === -1}
              >
                {this.props.criseOptionsLabel.btnHier}
              </button>
              <button
                id="btn_temps_reel"
                onClick={this.updateSliderValue}
                value={this.props.setBtnReel}
                disabled={this.props.setBtnReel === -1}
              >
                {this.props.criseOptionsLabel.btnTempsReel}
              </button>
              <button
                id="btn_1_jour"
                onClick={this.updateSliderValue}
                value={this.props.setBtn1J}
                disabled={this.props.setBtn1J === -1}
              >
                {this.props.criseOptionsLabel.btnUnJour}
              </button>
              <button
                id="btn_3_jours"
                onClick={this.updateSliderValue}
                // value={this.props.btnTroisJours}
                value={this.props.setBtn3J}
                // disabled={this.props.setApp === "AS" || this.props.setApp === "ASN" || this.props.setApp === "AS_NDPJ" || this.props.setApp === "AS_SCB"}
                disabled={this.props.setBtn3J === -1}
              >
                {this.props.criseOptionsLabel.btnTroisJours}
              </button>
              <button
                id="btn_7_jours"
                onClick={this.updateSliderValue}
                value={this.props.setBtn7J}
                disabled={this.props.setBtn7J === -1}
              >
                {this.props.criseOptionsLabel.btnSeptJours}
              </button>
            </div>
          </div>
        </div>
        <div>
          <button className="btn_signout" onClick={this.props.signOut}>
            {" "}
            <FontAwesomeIcon icon={faPowerOff}/>
          </button>
        </div>
      </header>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    typeMesureDebit: state.debit,
    criseOptionsLabel: state.criseOptionsLabel,
    toggleCrise: state.crise,
    toggleManualMode: state.manualMode,
    setApp: state.app,
    setBtnHier: state.boutonHier,
    setBtnReel: state.boutonReel,
    setBtn1J: state.boutonUnJour,
    setBtn3J: state.boutonTroisJour,
    setBtn7J: state.boutonSeptJour
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCriseToggle: () => dispatch(actionCreators.toggleCrise()),
    onManualModeToggle: () => dispatch(actionCreators.toggleManualMode()),
    onSetBtnHier: (id) => dispatch(actionCreators.setBtnHier(id)),
    onSetBtnReel: (id) => dispatch(actionCreators.setBtnReel(id)),
    onSetBtn1J: (id) => dispatch(actionCreators.setBtn1J(id)),
    onSetBtn3J: (id) => dispatch(actionCreators.setBtn3J(id)),
    onSetBtn7J: (id) => dispatch(actionCreators.setBtn7J(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header;
