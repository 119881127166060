import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadModules} from 'esri-loader';
import {
  bagStatus,
  buildingFreestyleStatus,
  // buildingStatusDefault,
  buildingStatus,
  streetStatus
} from '../utils/popup';
import {
  buildingRenderer,
  buildingPtsRenderer,
  buildingFreestyleRenderer,
  streetRenderer,
  bagRenderer
} from '../utils/renderer';
// import * as critGraphics from '../utils/critGraphics'
import * as actions from '../store/actions';
import stationPompage from "../assets/critGraphics/Station pompage.svg";
import stationMesure from "../assets/critGraphics/Station de mesure2.svg";

//Timer before changing scenario
let updateTimer = null;
const startUpdateTimer = (fct) => {
  clearTimeout(updateTimer);
  updateTimer = setTimeout(fct, 500);
};

export const WaterLevelMap = (props) => {
  const mapContainer = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);

  const dispatch = useDispatch();
  const onScenarioChange = (id) => dispatch(actions.changeScenario(id));
  const onMatchMediaChange = (mq) => dispatch(actions.matchMediaChange(mq));
  const onDataExportChange = (data) => dispatch(actions.changeDataExport(data));
  const onSearchToggle = () => dispatch(actions.toggleSearch());
  const onAlerteToggle = () => dispatch(actions.toggleAlerte());
  const onManualModeToggle = () => dispatch(actions.toggleManualMode());
  const currentScenario = useSelector(state => state.scenario);
  const mesureTypeDebit = useSelector(state => state.debit);

  // const app = useSelector(state => state.app);
  // let buildingStatus;
  // if (app === "AS" || app === "AS_NDPJ" || app === "AS_SCB") {
  //   buildingStatus = buildingStatusAS
  // } else {
  //   buildingStatus = buildingStatusDefault
  // }

  const getWaterHeightsUrls = () => {
    const waterUrls = [];
    const max =
      props.configuration.startScenario +
      props.configuration.stepScenario *
      props.configuration.nbScenario;

    for (
      let i = props.configuration.startScenario;
      i < max;
      i += props.configuration.stepScenario
    ) {
      waterUrls.push(props.configuration.waterUrl.concat(i, "/MapServer"));
    }
    return waterUrls;
  };
  const getDMWaterHeightsUrls = () => {
    const waterUrls = [];
    const max =
      props.configuration.startScenario +
      props.configuration.stepScenario *
      props.configuration.nbScenario;

    for (
      let i = props.configuration.startScenario;
      i < max;
      i += props.configuration.stepScenario
    ) {
      if (i === 41) {
        waterUrls.push(
          props.configuration.waterUrl.concat(68, "/MapServer")
        );
      } else if (i >= 42 && i <= 67) {
        waterUrls.push(
          props.configuration.waterUrl.concat(i, "/MapServer")
        );
      } else if (i === 68) {
        waterUrls.push(
          props.configuration.waterUrl.concat(69, "/MapServer")
        );
      } else {
        waterUrls.push(
          props.configuration.waterUrl.concat(i + 1, "/MapServer")
        );
      }
    }
    return waterUrls;
  };

  const getUrls = (url, offset) => {
    const urlsList = [];

    for (let i = 0; i < props.configuration.nbScenario; i++) {
      urlsList.push(url.concat(i + offset));
    }
    return urlsList;
  };
  const resetBuildingStats = () => {
    document.getElementById("statsIsole").innerHTML = "0";
    // document.getElementById("statsSsInonde").innerHTML = "0";
    // document.getElementById("statsPremInonde").innerHTML = "0";
    document.getElementById("statsTotalInonde").innerHTML = "0";
    document.getElementById("statsNbEvac").innerHTML = "0";
  };
  const resetStreetStats = () => {
    document.getElementById("statsStreet").innerHTML = "0";
  };
  const resetBagStats = () => {
    document.getElementById("statsSacs").innerHTML = "0";
  };
  const getBuildingStats = (
    id,
    view,
    buildingLayer,
    buildingPtsLayer,
    Query
  ) => {
    const calcBuildingStatsOnUpdate = (layerView) => {
      layerView.watch("updating", (value) => {
        if (!value) {
          // wait for the layer view to finish updating
          // and query all the features available for drawing.
          //Batiment isole
          const queryIsole = new Query();
          queryIsole.geometry = view.extent;
          queryIsole.where = "ETAT = 'Isolé (route inondée)'";
          buildingLayer[id]
            .queryFeatureCount(queryIsole)
            .then((results) => {
              document.getElementById("statsIsole").innerHTML = Number(results);
            });

          //Sous-sol inonde
          // const querySsInonde = new Query();
          // querySsInonde.where = "ETAT = 'Sous-sol inondé' OR ETAT = 'Eau atteint le bâtiment'";
          // querySsInonde.geometry = view.extent;
          // buildingLayer[id]
          //   .queryFeatureCount(querySsInonde)
          //   .then((results) => {
          //     document.getElementById("statsSsInonde").innerHTML = Number(results);
          //   });

          //Premier plancher inonde
          // const queryPremInonde = new Query();
          // queryPremInonde.where = "ETAT = 'Sous-sol et premier plancher inondé' OR ETAT = 'Premier plancher inondé (sans sous-sol)'";
          // queryPremInonde.geometry = view.extent;
          // buildingLayer[id]
          //   .queryFeatureCount(queryPremInonde)
          //   .then((results) => {
          //     document.getElementById("statsPremInonde").innerHTML = Number(results);
          //   });

          //Total bâtiments inondés
          const queryTotalInonde = new Query();
          queryTotalInonde.where =
            "ETAT = 'Sous-sol inondé' OR ETAT = 'Eau atteint le bâtiment' OR" +
            " ETAT = 'Sous-sol et premier plancher inondé' OR ETAT = 'Premier plancher inondé (sans sous-sol)'";
          queryTotalInonde.geometry = view.extent;
          // layerView
          buildingLayer[id]
            .queryFeatureCount(queryTotalInonde)
            .then((results) => {
              document.getElementById("statsTotalInonde").innerHTML = Number(results);
            });

          //Nb personnes a evacuer
          const sumEvac = {
            onStatisticField: "Nb_person",
            outStatisticFieldName: "Evac_sum",
            statisticType: "sum",
          };
          const queryNbEvac = new Query();
          queryNbEvac.where =
            "ETAT = 'Isolé (route inondée)' OR ETAT = 'Eau atteint le bâtiment' OR " +
            "ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'";
          queryNbEvac.outStatistics = [sumEvac];
          queryNbEvac.geometry = view.extent;
          buildingLayer[id].queryFeatures(queryNbEvac)
            .then((results) => {
              const stats = results.features[0].attributes;
              // this.props.onStatsEvacChange(Number(stats.Evac_sum))
              document.getElementById("statsNbEvac").innerHTML = Number(stats.Evac_sum);
            });
        }
      });
    };
    view.whenLayerView(buildingPtsLayer[id]).then((layerView) => {
      calcBuildingStatsOnUpdate(layerView);
    });
    view.whenLayerView(buildingLayer[id]).then((layerView) => {
      calcBuildingStatsOnUpdate(layerView);
    });
  };

  const getStreetStats = (
    id,
    view,
    streetLayer,
    Query
  ) => {
    const calcStreetStatsOnUpdate = (layerView) => {
      layerView.watch("updating", (value) => {
        if (!value) {
          // wait for the layer view to finish updating
          // and query all the features available for drawing.
          //Nb de km de route inondées
          const sumStreet = {
            onStatisticField: "Shape_Leng",
            outStatisticFieldName: "Street_sum",
            statisticType: "sum",
          };
          const queryStreet = new Query();
          queryStreet.outStatistics = [sumStreet];
          queryStreet.geometry = view.extent;
          streetLayer[id].queryFeatures(queryStreet)
            .then((results) => {
              const stats = results.features[0].attributes;
              document.getElementById("statsStreet").innerHTML = (Number(stats.Street_sum) / 1000).toFixed(1);
            });
        }
      });
    };
    view.whenLayerView(streetLayer[id]).then((layerView) => {
      calcStreetStatsOnUpdate(layerView);
    });
  };

  const getBagStats = (
    id,
    view,
    bagLayer,
    Query
  ) => {
    const calcBagStatsOnUpdate = (layerView) => {
      layerView.watch("updating", (value) => {
        if (!value) {
          // wait for the layer view to finish updating
          // and query all the features available for drawing.
          //Nb de sacs de sable
          const sumSacs = {
            onStatisticField: "Nb_Sacs",
            outStatisticFieldName: "Sacs_sum",
            statisticType: "sum",
          };
          const queryNbSacs = new Query();
          queryNbSacs.outStatistics = [sumSacs];
          queryNbSacs.geometry = view.extent;
          bagLayer[id].queryFeatures(queryNbSacs)
            .then((results) => {
              const stats = results.features[0].attributes;
              document.getElementById("statsSacs").innerHTML = Number(stats.Sacs_sum);
            });
        }
      });
    };
    view.whenLayerView(bagLayer[id]).then((layerView) => {
      calcBagStatsOnUpdate(layerView);
    });
  };

  // console.log(props.configuration.configName);
  useEffect(() => {
    // console.log("loading ", props);
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/layers/TileLayer",
        "esri/widgets/BasemapToggle",
        "esri/widgets/Search",
        "esri/tasks/support/Query",
        "esri/tasks/QueryTask",
        // "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/widgets/ScaleBar",
        "esri/widgets/Sketch",
        "esri/core/urlUtils"
      ],
      {css: true}
    ).then(
      ([
         Map,
         MapView,
         FeatureLayer,
         TileLayer,
         BasemapToggle,
         Search,
         Query,
         QueryTask,
         // Graphic,
         GraphicsLayer,
         ScaleBar,
         Sketch,
         urlUtils
       ]) => {
        // console.log("reload map");
        // Pour Prod seulement
        // urlUtils.addProxyRule({
        //   urlPrefix: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services",
        //   proxyUrl: "http://webapp.e-nundation.com/PHP/proxy.php"
        // });
        // urlUtils.addProxyRule({
        //   urlPrefix: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services",
        //   proxyUrl: "http://webapp.e-nundation.com/PHP/proxy.php"
        // });
        //Setting up configuration
        let waterUrls, buildingUrls, buildingPtsUrls, streetUrls, bagUrls, stationHydroUrls;
        if (props.configuration.configName === "deuxMontagnes") {
          waterUrls = getDMWaterHeightsUrls();
          buildingUrls = getUrls(props.configuration.buildingUrl, 2);
          buildingPtsUrls = getUrls(props.configuration.buildingPtsUrl, 2);
          streetUrls = getUrls(props.configuration.streetUrl, 2);
          bagUrls = getUrls(props.configuration.bagUrl, 2);
          stationHydroUrls = getUrls(props.configuration.stationHydro_Url, 2);
        } else {
          waterUrls = getWaterHeightsUrls();
          buildingUrls = getUrls(props.configuration.buildingUrl, 1);
          buildingPtsUrls = getUrls(props.configuration.buildingPtsUrl, 1);
          streetUrls = getUrls(props.configuration.streetUrl, 1);
          bagUrls = getUrls(props.configuration.bagUrl, 1);
          stationHydroUrls = getUrls(props.configuration.stationHydro_Url, 1);
        }
        //Prepare water heights TileLayer
        //Create multiple instances of TileLayer for water heights
        const waterLayer = waterUrls.map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        //Prepare buildings FeatureLayer
        //Set popup for buildings
        const buildingPopup = {
          title: "{ADRESSE}",
          outFields: ["*"],
          content: buildingStatus,
          fieldInfos: [
            {
              fieldName: "ETAT",
              label: " ",
            },
          ],
        };
        //Create multiple instances of FeatureLayer for buildings
        const buildingLayer = buildingUrls.map(
          (buildingUrl) =>
            new FeatureLayer({
              url: buildingUrl,
              id: "building",
              title: "État des bâtiments",
              popupTemplate: buildingPopup,
              renderer: buildingRenderer,
              minScale: 20000,
              maxScale: 100,
              visible: true,
            })
        );

        //Prepare buildings points FeatureLayer
        //Set popup for buildings points
        const buildingPtsPopup = {
          title: "Un seul bâtiment affecté",
          content: [
            {
              type: "text",
              // text: "État du bâtiment: <b>{ETAT}</b>",
              text: "État du bâtiment: <b>{clusterETA}</b>",
            },
          ],
        };
        //Configure clusters
        const buildingClusterConfig = {
          type: "cluster",
          clusterRadius: 100,
          popupTemplate: {
            title: "Groupe de bâtiments affectés",
            content: [
              {
                type: "text",
                text:
                // "Ce groupe représente <b>{cluster_count}</b> bâtiments. La majorité étant {cluster_type_ETAT}",
                  "Ce groupe représente <b>{cluster_count}</b> bâtiments. La majorité étant {cluster_type_clusterETA}",
              },
            ],
          },
        };
        //Create multiple instances of FeatureLayer for buildings points
        const buildingPtsLayer = buildingPtsUrls.map(
          (buildingPtsUrl) =>
            new FeatureLayer({
              url: buildingPtsUrl,
              id: "buildingPts",
              title: "État des bâtiments Pts",
              // outFields: ["ETAT"],
              outFields: ["clusterETA"],
              popupTemplate: buildingPtsPopup,
              renderer: buildingPtsRenderer,
              featureReduction: buildingClusterConfig,
              minScale: 300000,
              maxScale: 20000,
              visible: true,
            })
        );
        //Create FeatureLayer of buildings for freestyle tool
        const buildingFreestylePopup = {
          title: "{Adresse_im}",
          outFields: ["*"],
          content: buildingFreestyleStatus,
          fieldInfos: [
            {
              fieldName: "ETAT",
              label: " ",
            },
          ],
        };
        const buildingFreestyleLayer =
          new FeatureLayer({
              url: props.configuration.buildingFreestyleUrl,
              id: "freestyle",
              title: "Bâtiments freestyle",
              popupTemplate: buildingFreestylePopup,
              renderer: buildingFreestyleRenderer,
              minScale: 300000,
              maxScale: 0,
              visible: true,
            }
          );
        //Prepare streets FeatureLayer
        //Set popup for streets
        const streetPopup = {
          title: "{NOMRUE_C_D}",
          outFields: ["*"],
          content: streetStatus,
          fieldInfos: [
            {
              fieldName: "STATUS",
              label: " ",
            },
          ],
        };
        //Create multiple instances of FeatureLayer for streets
        const streetLayer = streetUrls.map(
          (streetUrl) =>
            new FeatureLayer({
              url: streetUrl,
              id: "street",
              title: "État du réseau routier",
              popupTemplate: streetPopup,
              renderer: streetRenderer,
              minScale: 20000,
              visible: true,
            })
        );
        //Prepare bags FeatureLayer
        //Set popup for bags
        const bagPopup = {
          title: "Digue temporaire",
          outFields: ["*"],
          content: bagStatus,
        };
        //Create multiple instances of FeatureLayer for sand bags
        const bagLayer = bagUrls.map(
          (bagUrl) =>
            new FeatureLayer({
              url: bagUrl,
              id: "bag",
              title: "Sacs de sable",
              popupTemplate: bagPopup,
              renderer: bagRenderer,
              // minScale: 10000,
              minScale: 30000,
              visible: true,
            })
        );
        //Prepare station hydrometrique FeatureLayer
        const stationHydroLayer = stationHydroUrls.map(
          (stationHydroUrl) =>
            new FeatureLayer({
              url: stationHydroUrl,
              id: "station",
              title: "Station hydrométrique",
              minScale: 1000000,
              maxScale: 0,
              renderer: {
                type: "simple", // autocasts as new SimpleRenderer()
                symbol: {
                  type: "picture-marker",  // autocasts as new SimpleMarkerSymbol()
                  url: stationMesure,
                  width: "48px",
                  height: "48px"
                }
              },
              popupTemplate: {
                outFields: ["*"],
                title: "Station {NomStation}",
                // content: mesureTypeDebitStn ? "Débit: {Niveau} m³/s" : "Niveau: {Niveau} m"
                content: "{type} : {Niveau} {mesure}"
              }
            })
        );
        //Prepare service essentiel FeatureLayer
        const serviceEssentielLayer = new FeatureLayer({
          url: props.configuration.serviceEssentiel_Url,
          id: "services",
          title: "Services essentiels",
          minScale: 1000000,
          maxScale: 0,
          renderer: {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker",  // autocasts as new SimpleMarkerSymbol()
              url: stationPompage,
              width: "48px",
              height: "48px"
            }
          },
          popupTemplate: {
            outFields: ["*"],
            title: "{Cat}",
            content: "{Nom} <br> {Adresse}"
          }
        });

        // Push all all critical graphics in Array
        // const critGraphic = [];
        // Object.entries(critGraphics).forEach(([name, exported]) => {
        //   critGraphic.push(new Graphic({
        //     geometry: exported.coord,
        //     symbol: exported.icone,
        //     attributes: exported.info,
        //     popupTemplate: {
        //       // autocasts as new PopupTemplate()
        //       title: "{nom}",
        //       content: "{cat}"
        //     }
        //   }))
        // });
        // const critGraphicslayer = new GraphicsLayer({
        //   id: "critGraphic",
        //   graphics: critGraphic,
        //   minScale: 20000
        // });

        // Photo georeference
        const photoLayer = props.configuration.photoUrls.map(
          (photoUrl) =>
            new TileLayer({
              url: photoUrl,
              id: "photo",
              title: "Inondation",
              opacity: 1,
              visible: true,
              resampling: true,
            }));
        //Prepare Zi FeatureLayer
        const zi2019Layer =
          new FeatureLayer({
            url: props.configuration.zi2019Url,
            id: "zi2019",
            title: "Zones inondables",
            minScale: 1000000,
            maxScale: 0,
            renderer: {
              type: "simple", // autocasts as new SimpleRenderer()
              symbol: {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [161, 66, 245, 0.5],
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 2,
                  color: [161, 66, 245],
                },
              },
            },
          });
        const zi2Layer =
          new FeatureLayer({
            url: props.configuration.zi2Url,
            id: "zi2",
            title: "Zones inondables",
            minScale: 1000000,
            maxScale: 0,
            renderer: {
              type: "simple", // autocasts as new SimpleRenderer()
              symbol: {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [161, 66, 245, 0.5],
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 2,
                  color: [161, 66, 245],
                },
              },
            },
          });
        const zi20Layer =
          new FeatureLayer({
            url: props.configuration.zi20Url,
            id: "zi20",
            title: "Zones inondables",
            minScale: 1000000,
            maxScale: 0,
            renderer: {
              type: "simple", // autocasts as new SimpleRenderer()
              symbol: {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [161, 66, 245, 0.5],
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 2,
                  color: [161, 66, 245],
                },
              },
            },
          });
        const zi100Layer =
          new FeatureLayer({
            url: props.configuration.zi100Url,
            id: "zi100",
            title: "Zones inondables",
            minScale: 1000000,
            maxScale: 0,
            renderer: {
              type: "simple", // autocasts as new SimpleRenderer()
              symbol: {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [161, 66, 245, 0.5],
                outline: {
                  // autocasts as new SimpleLineSymbol()
                  width: 2,
                  color: [161, 66, 245],
                },
              },
            },
          });
        //Custom basemap
        // let customBasemap = new Basemap({
        //     baseLayers: [
        //         new VectorTileLayer({
        //             portalItem: {
        //                 // id: "642f3ebe062c4bc7bce80b1b7543e59a" //routes vertes
        //                 id: "81626112d36b40ac9cd0785840d732e2"  //eau bleu
        //             }
        //         })
        //     ]
        // });
        //Initialize to starting scenario
        onScenarioChange(props.configuration.scenarioDepart);
        const id = props.configuration.scenarioDepart;
        //Set map
        const map = new Map({
          basemap: "hybrid",
          // basemap: customBasemap,
          // layers: [waterLayer[id], buildingLayer[id], buildingPtsLayer[id], critGraphicslayer],
          layers: [waterLayer[id]]
        });

        //Set view
        const view = new MapView({
          container: mapContainer.current,
          map: map,
          center: props.configuration.centerView,
          sliderPosition: "top-left",
          zoom: props.configuration.centerViewZoom,
          constraints: {
            maxZoom: 20,
            minZoom: 11,
          },
          // For SearchBuilding
          popup: {
            dockEnabled: true,
            dockOptions: {
              position: "top-left",
              breakpoint: false,
            },
          },
          // highlightOptions: {
          //   color: [218, 66, 245, 1],
          //   haloOpacity: 0.9,
          //   fillOpacity: 1
          // }
        });
        setMapInstance(view);

        // view.on("click", function (event) {
        //         //   // event is the event handle returned after the event fires.
        //         //   console.log(event.mapPoint.latitude, event.mapPoint.longitude);
        //         // });

        //Set basemap toggle
        const basemapToggle = new BasemapToggle({
          view: view,
          nextBasemap: "streets-navigation-vector",
          // nextBasemap: customBasemap,
          // titleVisible: true
        });
        view.ui.add(basemapToggle, "bottom-right");
        //Set search bar
        const search = new Search({
          view: view,
        });
        view.ui.add(search, "top-left");
        // Set scaleBar
        const scaleBar = new ScaleBar({
          view: view,
          style: "line",  // Or "ruler"
          unit: "metric" // The scale bar displays both metric and non-metric units.
          // unit: "dual" // The scale bar displays both metric and non-metric units.
        });
        view.ui.add(scaleBar, {
          position: "bottom-left"
        });

        const selectLayer = new GraphicsLayer(); // For Sketch widget
        selectLayer.id = "sketchSelectLayer";
        const sketch = new Sketch({
          layer: selectLayer,
          view: view,
          availableCreateTools: ["polygon", "rectangle", "circle"],
          creationMode: "continuous",
          container: document.getElementById("div-manual"),
          layout: "horizontal"

        });
        view.ui.add(sketch);

        //Function to change scenario
        let layerOrder = 0;
        const changeScenario = (id) => {
          const currentState = props.store.getState();
          //Close popups
          const popupContainer = document.getElementsByClassName("esri-popup__main-container");
          if (typeof popupContainer[0] !== 'undefined') {
            popupContainer[0].style.display = "none";
          }
          //Close SearchDiv and reset
          if (currentState.search) {
            onSearchToggle()
          }
          if (currentState.alerte) {
            onAlerteToggle()
          }
          if (currentState.manualMode) {
            onManualModeToggle()
          }
          onDataExportChange([]);
          document.getElementById("printResults").innerHTML = "";
          //Remove layers and add only toggled layers
          map.removeAll();
          if (currentState.photo) {
            map.addMany(photoLayer);
            for (let i = 0; i < photoLayer.length; i++) {
              map.reorder(photoLayer[i], i);
              layerOrder = i + 1;
            }
            // map.addMany(photoLayer);
            // map.reorder(photoLayer, 0)
            // layerOrder = 1
          }
          if (currentState.zi2019) {
            map.add(zi2019Layer);
            // map.reorder(photoLayer, 0)
            // layerOrder = 1
          }
          if (currentState.zi2) {
            map.add(zi2Layer);
            // map.reorder(photoLayer, 0)
            // layerOrder = 1
          }
          if (currentState.zi20) {
            map.add(zi20Layer);
            // map.reorder(photoLayer, 0)
            // layerOrder = 1
          }
          if (currentState.zi100) {
            map.add(zi100Layer);
            // map.reorder(photoLayer, 0)
            // layerOrder = 1
          }
          if (currentState.water) {
            map.add(waterLayer[id]);
            map.reorder(waterLayer[id], layerOrder)
          }
          if (currentState.max) {
            map.add(serviceEssentielLayer);
            map.reorder(serviceEssentielLayer, layerOrder + 1)
          }
          if (currentState.min) {
            map.add(stationHydroLayer[id]);
            map.reorder(stationHydroLayer[id], layerOrder + 2)
          }
          if (currentState.building) {
            map.add(buildingLayer[id]);
            map.add(buildingPtsLayer[id]);
            // map.add(critGraphicslayer);
            map.reorder(buildingLayer[id], layerOrder + 3);
            map.reorder(buildingPtsLayer[id], layerOrder + 3);
            // map.reorder(critGraphicslayer, layerOrder + 3)
          }
          if (currentState.street) {
            map.add(streetLayer[id]);
            map.reorder(streetLayer[id], layerOrder + 4)
          }
          if (currentState.bag) {
            map.add(bagLayer[id]);
            map.reorder(bagLayer[id], layerOrder + 5)
          }
          //Get stats from new layers
          if (currentState.building) {
            getBuildingStats(id, view, buildingLayer, buildingPtsLayer, Query);
          }
          if (currentState.street) {
            getStreetStats(id, view, streetLayer, Query);
          }
          if (currentState.bag) {
            getBagStats(id, view, bagLayer, Query);
          }
        };

        //Slider event to change scenario
        document.addEventListener("waterLevel", (event) => {
          startUpdateTimer(() => {
            onScenarioChange(event.detail);
            changeScenario(event.detail);
          });
        });

        //Subscribe to manage layer toggles
        // this.unsubscribe = this.props.store.subscribe(() => {
        props.store.subscribe(() => {
          // console.log("[Subscription]", props.store.getState());
          const currentState = props.store.getState();
          //Build array with toggled layers
          const currentLayers = [];
          for (let i = 0; i < map.layers.items.length; i++) {
            currentLayers.push(map.layers.items[i].id);
          }
          // console.log("current layer", currentLayers);
          //Manage photo layer toggle
          if (currentState.photo && !currentLayers.includes("photo")) {
            map.addMany(photoLayer);
            for (let i = 0; i < photoLayer.length; i++) {
              map.reorder(photoLayer[i], i);
              layerOrder = i + 1;
            }
          }
          if (!currentState.photo && currentLayers.includes("photo")) {
            map.removeMany(photoLayer);
          }
          //Manage zi layer toggle
          if (currentState.zi2019 && !currentLayers.includes("zi2019")) {
            map.add(zi2019Layer);
          }
          if (!currentState.zi2019 && currentLayers.includes("zi2019")) {
            map.remove(zi2019Layer);
          }
          if (currentState.zi2 && !currentLayers.includes("zi2")) {
            map.add(zi2Layer);
          }
          if (!currentState.zi2 && currentLayers.includes("zi2")) {
            map.remove(zi2Layer);
          }
          if (currentState.zi20 && !currentLayers.includes("zi20")) {
            map.add(zi20Layer);
          }
          if (!currentState.zi20 && currentLayers.includes("zi20")) {
            map.remove(zi20Layer);
          }
          if (currentState.zi100 && !currentLayers.includes("zi100")) {
            map.add(zi100Layer);
          }
          if (!currentState.zi100 && currentLayers.includes("zi100")) {
            map.remove(zi100Layer);
          }
          //Manage water layer toggle
          if (currentState.water && !currentLayers.includes("water")) {
            map.add(waterLayer[currentState.scenario]);
            map.reorder(waterLayer[currentState.scenario], layerOrder);
          }
          if (!currentState.water && currentLayers.includes("water")) {
            map.remove(waterLayer[currentState.scenario]);
          }
          //Manage station hydrometrique layer toggle
          if (currentState.min && !currentLayers.includes("station")) {
            map.add(stationHydroLayer[currentState.scenario]);
            map.reorder(stationHydroLayer[currentState.scenario], layerOrder + 2)
          }
          if (!currentState.min && currentLayers.includes("station")) {
            map.remove(stationHydroLayer[currentState.scenario]);
          }
          //Manage services essentiels layer toggle
          if (currentState.max && !currentLayers.includes("services")) {
            map.add(serviceEssentielLayer);
            map.reorder(serviceEssentielLayer, layerOrder + 1)
          }
          if (!currentState.max && currentLayers.includes("services")) {
            map.remove(serviceEssentielLayer);
          }
          //Manage building layer toggle
          if (currentState.building && !currentLayers.includes("building")) {
            map.add(buildingLayer[currentState.scenario]);
            map.add(buildingPtsLayer[currentState.scenario]);
            // map.add(critGraphicslayer);
            map.reorder(buildingLayer[currentState.scenario], layerOrder + 3);
            map.reorder(buildingPtsLayer[currentState.scenario], layerOrder + 3);
            // map.reorder(critGraphicslayer, layerOrder + 3)
          }
          if (!currentState.building && currentLayers.includes("building")) {
            map.remove(buildingLayer[currentState.scenario]);
            map.remove(buildingPtsLayer[currentState.scenario]);
            // map.remove(critGraphicslayer);
          }
          if (currentState.building) {
            getBuildingStats(currentState.scenario, view, buildingLayer, buildingPtsLayer, Query)
            // startUpdateTimer(() => {
            //   getBuildingStats(currentState.scenario, view, buildingLayer, buildingPtsLayer, Query)
            // });
          } else {
            resetBuildingStats()
          }
          //Manage street layer toggle
          if (currentState.street && !currentLayers.includes("street")) {
            map.add(streetLayer[currentState.scenario]);
            map.reorder(streetLayer[currentState.scenario], layerOrder + 4)
          }
          if (!currentState.street && currentLayers.includes("street")) {
            map.remove(streetLayer[currentState.scenario]);
          }
          if (currentState.street) {
            // console.log("get street stats");
            getStreetStats(currentState.scenario, view, streetLayer, Query)
          } else {
            // console.log("reset street stats");
            resetStreetStats()
          }
          //Manage bag layer toggle
          if (currentState.bag && !currentLayers.includes("bag")) {
            map.add(bagLayer[currentState.scenario]);
            map.reorder(bagLayer[currentState.scenario], layerOrder + 5)
          }
          if (!currentState.bag && currentLayers.includes("bag")) {
            map.remove(bagLayer[currentState.scenario]);
          }
          if (currentState.bag) {
            getBagStats(currentState.scenario, view, bagLayer, Query)
          } else {
            resetBagStats()
          }
          //Manage manual mode toggle
          const popupContainer = document.getElementsByClassName("esri-popup__main-container");
          if (currentState.manualMode) {
            if (currentLayers.includes("building")) {
              document.getElementById("printResults").innerHTML = "";
            }
            if (typeof popupContainer[0] !== 'undefined') {
              popupContainer[0].style.display = "none";
            }
            map.removeAll();
            map.add(buildingFreestyleLayer);
            map.add(selectLayer);
            map.add(manualResultsLayer);
          }
          if (!currentState.manualMode && currentLayers.includes("freestyle")) {
            if (typeof popupContainer[0] !== 'undefined') {
              popupContainer[0].style.display = "none";
            }
            document.getElementById("printResults").innerHTML = "";
            map.removeAll();
            selectLayer.removeAll();
            manualResultsLayer.removeAll();
            const posLayersList = {
              water: waterLayer,
              building: buildingLayer,
              street: streetLayer,
              bag: bagLayer,
              min: stationHydroLayer,
              max: serviceEssentielLayer
            };
            const keys = Object.keys(posLayersList);
            for (let i = 0; i < keys.length; i++) {
              if (currentState[keys[i]]) {
                if (i === 5) {
                  map.add(posLayersList[keys[i]]);
                } else {
                  map.add(posLayersList[keys[i]][currentState.scenario]);
                  if (i === 1) {  // Add critGraphics and points for buildings
                    map.add(buildingPtsLayer[currentState.scenario]);
                    // map.add(critGraphicslayer);
                  }
                }
              }
            }
          }
        });

        // SearchBuilding
        // Create graphics layers and symbol to use for displaying the results of queries
        const manualResultsLayer = new GraphicsLayer();
        manualResultsLayer.id = "manualResults";
        const floodResultsLayer = new GraphicsLayer();
        floodResultsLayer.id = "floodResults";
        const attributeName = document.getElementById("attSelect");
        const resultSymbol = {
          type: "simple-fill", // autocasts as new SimpleFillSymbol()
          // color: "red",
          outline: {
            // autocasts as new SimpleLineSymbol()
            // color: [128, 128, 128, 0.5],
            color: [52, 235, 104, 1],
            width: "3px",
          },
        };

        // Executes each time the button is clicked
        const doQuery = () => {
          const currentState = props.store.getState();
          if (currentState.manualMode) {
            manualResultsLayer.removeAll();
            const dataToExport = [];
            // Get building info to export
            const queryBat = (polygon) => {
              return new Promise(async (resolve, reject) => {
                let queryFreestyle = buildingFreestyleLayer.createQuery();
                queryFreestyle.spatialRelationship = "intersects";
                queryFreestyle.returnGeometry = true;
                // queryFreestyle.outfields = ["telephone"];
                queryFreestyle.outfields = ["*"];
                queryFreestyle.geometry = polygon;
                buildingFreestyleLayer.queryFeatures(queryFreestyle).then(response => {
                  for (let i = 0; i < response.features.length; i++) {
                    const buildingInfo = {};
                    buildingInfo.adresse = response.features[i].attributes.Adresse_im;
                    buildingInfo.etat = "n/a";
                    buildingInfo.h_eau_ter = "n/a";
                    buildingInfo.nb_person = response.features[i].attributes.Nb_person;
                    buildingInfo.nb_sacs = "n/a";
                    buildingInfo.volume = "n/a";
                    buildingInfo.courriel = response.features[i].attributes.courriel;
                    buildingInfo.telephone = response.features[i].attributes.telephone;
                    buildingInfo.scenario = "n/a";
                    dataToExport.push(buildingInfo);
                    // console.log("length: ", response.features.length);
                    // console.log("buildingInfo: ", buildingInfo)
                  }
                  // console.log("dataToExport: ", dataToExport);
                  // console.log("Number of buildings: ", response.features.length);
                  if (dataToExport.length === 0) {
                    document.getElementById("printResults").innerHTML =
                      "Aucun bâtiment sélectionné";
                  } else if (dataToExport.length === 1) {
                    document.getElementById("printResults").innerHTML =
                      "1 bâtiment sélectionné";
                  } else {
                    document.getElementById("printResults").innerHTML =
                      dataToExport.length + " bâtiments sélectionnés";
                  }
                  const peakResults = response.features.map((feature) => {
                    feature.symbol = resultSymbol;
                    feature.popupTemplate = buildingFreestylePopup;
                    return feature;
                  });
                  manualResultsLayer.addMany(peakResults);
                  map.add(manualResultsLayer);
                  view.popup.open({
                    features: peakResults,
                    featureMenuOpen: true,
                    updateLocationEnabled: true,
                  });
                  onDataExportChange(dataToExport);
                });
              })
            };
            const queryBats = async () => {
              const polygons = [];
              for (let i = 0; i < selectLayer.graphics.items.length; i++) {
                polygons.push(selectLayer.graphics.items[i].geometry);
              }
              await Promise.all(polygons.map(polygon => queryBat(polygon)));
            };
            queryBats().then();
          } else {
            floodResultsLayer.removeAll();
            // Point QueryTask to URL of feature service
            const qTask = new QueryTask({
              url: buildingUrls[currentState.scenario],
            });
            // Set the query parameters to always return geometry and all fields.
            // Returning geometry allows us to display results on the map/view
            const params = new Query({
              geometry: view.extent,
              returnGeometry: true,
              outFields: ["*"],
              where: "ETAT = " + attributeName.value
            });
            // params.where = "ETAT = " + attributeName.value;
            // executes the query and calls getResults() once the promise is resolved
            // promiseRejected() is called if the promise is rejected
            qTask.execute(params).then(getResults).catch(promiseRejected);
          }
        };
        // Called each time the promise is resolved
        const getResults = (response) => {
          // Loop through each of the results and assign a symbol and PopupTemplate
          // to each so they may be visualized on the map
          const peakResults = response.features.map((feature) => {
            feature.symbol = resultSymbol;
            feature.popupTemplate = buildingPopup;
            return feature;
          });
          // Clear the results from a previous query
          floodResultsLayer.removeAll();
          // Add the results from the current query
          floodResultsLayer.addMany(peakResults);
          map.add(floodResultsLayer);
          //Animate to the results after they are added to the map
          // view
          //   .goTo({target: peakResults, zoom: 16}, {animate: false})
          //   .then(function () {
          //     view.popup.open({
          //       features: peakResults,
          //       featureMenuOpen: true,
          //       updateLocationEnabled: true,
          //     });
          //   })
          //   .catch(function (error) {
          //     if (error.name !== "AbortError") {
          //       console.error(error);
          //     }
          //   });
          view.popup.open({
            features: peakResults,
            featureMenuOpen: true,
            updateLocationEnabled: true,
            // updateLocationEnabled: false
          });
          // Print the number of results returned to the user
          if (peakResults.length === 0) {
            document.getElementById("printResults").innerHTML =
              "Aucun bâtiment trouvé";
          } else if (peakResults.length === 1) {
            document.getElementById("printResults").innerHTML =
              "1 bâtiment trouvé";
          } else {
            document.getElementById("printResults").innerHTML =
              peakResults.length + " bâtiments trouvés";
          }
          // Get building info to export
          const getBuildingInfo = (peakResults) => {
            const dataToExport = [];
            peakResults.forEach((currentPeakResult) => {
              const buildingInfo = {};
              if (currentPeakResult.attributes.ETAT === "Sous-sol et premier plancher inondé" || currentPeakResult.attributes.ETAT === "Sous-sol inondé") {
                buildingInfo.etat = "Inondé"
              } else {
                buildingInfo.etat = currentPeakResult.attributes.ETAT
              }
              let adresse = currentPeakResult.attributes.ADRESSE;
              buildingInfo.no_civ = adresse.slice(0, adresse.search(" "));
              buildingInfo.rue = adresse.slice(adresse.search(" ") + 1);
              buildingInfo.h_eau_ter = currentPeakResult.attributes.H_eau_ter.toFixed(2);
              buildingInfo.nb_person = currentPeakResult.attributes.Nb_person;
              buildingInfo.nb_sacs = currentPeakResult.attributes.Nb_Sacs;
              buildingInfo.volume = currentPeakResult.attributes.Volume;
              buildingInfo.courriel = currentPeakResult.attributes.courriel;
              buildingInfo.telephone = currentPeakResult.attributes.telephone;
              buildingInfo.scenario = props.labels[currentScenario]
                .concat(mesureTypeDebit ? " m³/s" : " m");
              dataToExport.push(buildingInfo);
            });
            const compareObjects = (object1, object2, key) => {
              const obj1 = object1[key].toUpperCase();
              const obj2 = object2[key].toUpperCase();
              if (obj1 < obj2) {
                return -1
              }
              if (obj1 > obj2) {
                return 1
              }
              return 0
            };
            dataToExport.sort((book1, book2) => {
              return compareObjects(book1, book2, 'rue')
            });
            return dataToExport;
          };
          onDataExportChange(getBuildingInfo(peakResults))
        };

        // Called each time the promise is rejected
        function promiseRejected(error) {
          console.error("Promise rejected: ", error.message);
        }

        document.getElementById("searchBtn").addEventListener("click", doQuery);

        //TO SOLVE problem update stats
        // view.watch('interacting', (value) => {
        //     if (value===true) {
        //         console.log("interacting")
        //     } else {
        //         console.log("not!");
        //         this.getStats(id, view, buildingLayer, Query, outputIsole, outputSsInonde, outputPremInonde, outputTotalInonde, outputNbEvac);
        //     }
        // });
        // Media query
        // const mq = window.matchMedia("(max-width: 1024px)");
        // media query event handler
        if (matchMedia) {
          const mq = window.matchMedia("(max-width: 1024px)");
          mq.addListener(() => onMatchMediaChange(mq));
          // this.WidthChange(mq);
        }
      }
    );

    return () => {
      setMapInstance(null)
      // if (view) {
      //     // destroy the map view
      //     view.container = null;
      //     // unsubscribe();  //not sure if this works?
      // }
    }
    // }, [mapContainer, getDMWaterHeightsUrls, getUrls, getWaterHeightsUrls, props]);
  }, [mapContainer]);

  // componentWillUnmount()
  // {
  //     if (this.view) {
  //         // destroy the map view
  //         this.view.container = null;
  //         this.unsubscribe();  //not sure if this works?
  //     }
  // }
  return (<div className="map" ref={mapContainer}/>);
};

// const mapStateToProps = (state) => {
//   return {
//     currentScenario: state.scenario,
//     // toggleWater: state.water,
//     // toggleBuilding: state.building,
//     // toggleStreet: state.street,
//     // toggleBag: state.bag,
//     // toggleMin: state.min,
//     // toggleMax: state.max,
//     // toggleSearch: state.search,
//     // toggleAlerte: state.alerte,
//     mesureTypeDebit: state.debit
//   };
// };
//
// const mapDispatchToProps = (dispatch) => {
//   return {
//     onScenarioChange: (id) => dispatch(actionCreators.changeScenario(id)),
//     onMatchMediaChange: (mq) => dispatch(actionCreators.matchMediaChange(mq)),
//     onDataExportChange: (data) => dispatch(actionCreators.changeDataExport(data)),
//     onSearchToggle: () => dispatch(actionCreators.toggleSearch()),
//     onAlerteToggle: () => dispatch(actionCreators.toggleAlerte()),
//     onManualModeToggle: () => dispatch(actionCreators.toggleManualMode())
//   };
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(WaterLevelMap);