import React from "react";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
import Header from "../components/Header";
import BottomPanel from "../components/BottomPanel";
import {WaterLevelMap} from "../components/WaterLevelMap";
import {DM} from "../constants/appsConstants";
import {Mentions} from "../components/Mentions";
import {Legende} from "../components/Legende";
import Validation from "../components/Validation";

export class AppDeuxMontagnes extends React.Component {
  render() {
    this.props.onSetApp("DM");
    this.props.onTypeMesureChange();  //For "Niveau d'eau" instead of "debit"
    return (
      <div className="app">
        <Header
          scenarioDepart={DM.scenarioDepart}
          nbScenario={DM.nbScenario}
          labels={DM.labels}
          btn2019={DM.btn2019}
          // btnHier={DM.btnHier}
          // btnTempsReel={DM.btnTempsReel}
          // btnUnJour={DM.btnUnJour}
          // btnTroisJours={DM.btnTroisJours}
          yearMarkers={DM.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={DM.scenarioDepart}
          labels={DM.labels}
          dataKm={DM.dataKm}
          dataSacs={DM.dataSacs}
          dataIsole={DM.dataIsole}
          // dataSS={DM.dataSS}
          // dataPrem={DM.dataPrem}
          dataTot={DM.dataTot}
          dataEvac={DM.dataEvac}
          // exportDataSet={this.state.exportDataSet}
        />

        <WaterLevelMap
          configuration={DM}
          // exportDataSetCallback={this.callbackFunction}
          labels={DM.labels}
          store={this.props.store}
        />
        <Legende/>
        <Mentions/>
        <Validation/>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetApp: (id) => dispatch(actionCreators.setApp(id)),
    onTypeMesureChange: () => dispatch(actionCreators.changeTypeMesure()),
  };
};

export default connect(null, mapDispatchToProps)(AppDeuxMontagnes);